import type { SearchResponse } from '@vue-storefront/prestashop-api';
import { debounce } from 'lodash-es';
import { useSdk } from '~/sdk';

interface SearchState {
  query: string;
  page: number;
  data: SearchResponse['psdata'] | null;
  loading: boolean;
  error: string | null;
  componentSearch : boolean;
}

export const useSearch = () => {
  const { locale } = useI18n();
  const router = useRouter();
  const route = useRoute();

  const state = useState<SearchState>((): SearchState => ({
    query: route.query.s ? route.query.s as string : '',
    page: route.query.page ? Number(route.query.page) : 1,
    data: { products: [], facets: {}, filters: {}, pagination: {} },
    loading: false,
    error: null,
    componentSearch: false
  }));

  async function search() {
    console.log('searching...');

    if (state.value.loading) { return };

    state.value.loading = true;

    const data = await useSdk().commerce.search({
      //@ts-ignore
      s: state.value.query as string,
      //@ts-ignore
      page: state.value.page,
    });

    if (data) {
      state.value.data = data;
    }

    state.value.loading = false;
    state.value.componentSearch = false;  
  }
 
  watch(locale, search);

  watch(() => [route.query.s, route.query.page], async (query) => {
    state.value.query = route.query.s as string;
    state.value.page = route.query.page ? Number(route.query.page) : 1;
    await search();
  }, { deep: true });

  watch(() => state.value.page, async () => { 
    await router.push({ query: { s: state.value.query, page: state.value.page } });
  }, { deep: true });

  watch(() => state.value.query, async () => {
    state.value.page = 1;
    await router.push({ query: { s: state.value.query, page: state.value.page } });
  });

  return {
    ...toRefs(state.value),
    search
  };
};
